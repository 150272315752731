import { useEffect, useRef, useState } from "react";
import ComplaintService from "../../Services/ComplaintService";
import Options from "../common/Options";
import SideBar from "../common/SideBar";


const $ = window.jQuery;


export default function Complaint() {


    const dialog = useRef();
    const ComplaitId = useRef(0);
    //const ComplaintStatus = ["Pending", "Resolved"];

    const updateData = () => {

        ComplaintService.GetComplaints().then(r => {

            let data = r.map(i => {
                return i;
            });
            setData(data);
            

        });
    };
    const [data, setData] = useState([]);
    useEffect(updateData, [])
    useEffect(() => {
        if(data.length > 0) {
            $('#ComplaintDataTable').DataTable();
        }
    }, [data])

    return <div id="wrapper">

        <SideBar />


        <div id="content-wrapper" className="d-flex flex-column">

            <div id="content">

                <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">

                    <form className="form-inline">
                        <button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3">
                            <i className="fa fa-bars"></i>
                        </button>
                    </form>


                    <Options />

                </nav>

                <div className="container-fluid">

                    <h1 className="h3 mb-2 text-gray-800">Complaint Management</h1>


                    <div className="card shadow mb-4">
                        <div className="card-header py-3">
                            <div className="row">
                                <div className="col-6 d-flex align-items-center">
                                    <h6 className="m-0 font-weight-bold text-primary">Complaint Management</h6>
                                </div>
                                <div className="col-6 d-flex justify-content-end">

                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive">

                                <table className="table table-bordered" id="ComplaintDataTable" width="100%" cellSpacing="0">
                                    <thead>
                                        <tr>
                                            <th>Titile</th>
                                            <th>Name </th>
                                            <th>Phone number</th>
                                            <th>Status</th>
                                            <th>Description</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tfoot>
                                        <tr>
                                            <th>Titile</th>
                                            <th>Name </th>
                                            <th>Phone number</th>
                                            <th>Status</th>
                                            <th>Description</th>
                                            <th></th>
                                        </tr>
                                    </tfoot>
                                    <tbody>
                                        {
                                             data.map(i => {
                                                return <tr id={i.id}>
                                                <td>{i.title}</td>
                                                <td>{i.name}</td>
                                                <td><a href={`tel:${i.phoneNumber}`}>{i.phoneNumber}</a></td>
                                                <td className='status' style={{ backgroundColor: i.status == 'Pending'? '#df9017': '#18a155', color: 'white', fontWeight: 'bold' }}>{i.status}</td>
                                                <td>{i.description}</td>
                                                <td>
                                                    <button type="button" className="btn btn-outline-primary" data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo"
                                                    onClick={() => {
                                                        ComplaitId.current = i.id;
                                                        document.querySelector('#modal-table #title-value').innerText = i.id;
                                                        document.querySelector('#modal-table #name-value').innerText = i.name;
                                                        document.querySelector('#modal-table #phoneNumber-value').innerText = i.phoneNumber;
                                                        document.querySelector('#modal-table #status-value').value = i.status == 'Pending'? 0: 1;
                                                        document.querySelector('#modal-table #description-value').innerText = i.description;
                                                    }
                                                    }>Review</button>
                                                </td>
                                            </tr>
                                             })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>


                    <div className="modal fade" id="exampleModal" tabIndex="-1" role="dialog"
                        aria-labelledby="exampleModalLabel" aria-hidden="false" ref={dialog}>



                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">Update Data</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="container mt-4">
                                        <h1>Complaint</h1>
                                        <table id="modal-table" className="table table-bordered">
                                            <tbody>
                                                <tr>
                                                    <th>Name</th>
                                                    <td id="name-value"></td>
                                                </tr>
                                                <tr>
                                                    <th>Phone Number</th>
                                                    <td id="phoneNumber-value"></td>
                                                </tr>
                                                {/* <tr>
                                                    <th id='title-value'>Title</th>
                                                    <td>string</td>
                                                </tr> */}
                                                <tr>
                                                    <th>Status</th>
                                                    <td><select id="status-value" style={{ borderRadius: '17px', borderWidth: 'thick', padding: '6px', width: '100%' }}>
                                                        <option value='0'>Pending</option>
                                                        <option value='1'>Resolved</option>
                                                    </select></td>
                                                </tr>
                                                <tr>
                                                    <th>Description</th>
                                                    <td id="description-value">string</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                    <button type="button" className="btn btn-primary" onClick={(e) => {

                                        ComplaintService.ChangeComplaintsStatus({
                                            id: ComplaitId.current,
                                            status: Number(document.getElementById('status-value').value)
                                        }).then(r => {
                                            updateData();
                                            $('#exampleModal').hide();
                                        });
                                    }}>Update Status</button>
                                </div>
                                {/* <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={(e) => {
                                        e.preventDefault();
                                        
                                       
                                    }}>Close</button>
                                    <button type="submit" className="btn btn-primary">Update</button>
                                </div> */}

                            </div>
                        </div>
                    </div>


                </div>

            </div>

            <footer className="sticky-footer bg-white">
                <div className="container my-auto">
                    <div className="copyright text-center my-auto">
                        <span>Copyright &copy; FUAIS 2023</span>
                    </div>
                </div>
            </footer>

        </div>

    </div>
}