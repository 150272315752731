import axios from "axios";
import { Service } from "./Service";

 class AuthService extends Service {
    Login(username, password) {
        axios.post(this.baseURL + 'Auth/Login', {
            "userName": username,
            "password": password,
            "deviceToken": "string",
            "platform": "string",
            "version": "string",
            "language": "string"
          }).then(r => {
            console.log(r);
            if(r.status == 200) {
                localStorage.setItem('user', JSON.stringify(r.data.result));
                localStorage.setItem('accessToken', r.data.result[0].accessToken);
                window.location.reload();
            }
          });
    }
}


export default new AuthService();