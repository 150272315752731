import { useEffect, useRef, useState } from "react";
import RestaurantBrancheService from "../../Services/RestaurantBrancheService";
import SideBar from "../common/SideBar";
import Options from "../common/Options";
import RestaurantService from "../../Services/RestaurantService";
import Swal from "sweetalert2";
import OrderService from "../../Services/OrderService";




export default function Layout({  children  }) {

    return <div id="wrapper">
        <SideBar />
        <div id="content-wrapper" className="d-flex flex-column">
            <div id="content">
                <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
                    <form className="form-inline">
                        <button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3">
                            <i className="fa fa-bars"></i>
                        </button>
                    </form>
                    <Options />
                </nav>
                

            </div>

            {children}
            <footer className="sticky-footer bg-white">
                <div className="container my-auto">
                    <div className="copyright text-center my-auto">
                        <span>Copyright &copy; FUAIS 2023</span>
                    </div>
                </div>
            </footer>

        </div>

    </div>
}