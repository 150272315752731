

import { Service } from "./Service";
import axios from "axios";

class MenuService extends Service {
    name = 'Menu';


    async GetMenu() {
        let result = [];
        let r = await axios.get(this.baseURL + this.name + '/GetMenu', this.config);
        if (r.status == 200) {
            result = r.data.result;
        }
        return result;
    }

    async AddEditMenu(obj) {

        let r = await axios.post(this.baseURL + this.name + '/AddEditMenu', obj, this.config);
        console.log(r);
        if (r.status == 200) {
            return r;
        }
        return [];
    }


}


export default new MenuService();