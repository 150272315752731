import AuthService from "../Services/AuthService";
import Controller from "./Controller";

class LoginController extends Controller {

    submit = (e) => {
        e.preventDefault();
        const data = new FormData(e.target);

        const value = Object.fromEntries(data.entries());
        AuthService.Login(value.username, value.password);
        console.log(value);
    }

}

export default new LoginController();