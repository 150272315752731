import { useEffect, useState } from "react";
import DeliveryPriceService from "../../Services/DeliveryPriceService";
import { json } from "react-router-dom";
import Options from "../common/Options";
import SideBar from "../common/SideBar";

const $ = window.jQuery;



function importAres(data, filename) {
    const headers = Object.keys(data[0]);
    const csvContent = [
        headers.join(','),
        ...data.map((item) => headers.map((header) => item[header]).join(','))
    ].join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = filename || 'data.csv';
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
}




function haveTerm(term = '', string = '') {
    return string.toLowerCase().includes(term.toLowerCase());
}

function exportAreas(fileInput, callback, updateData) {


    const file = fileInput.files[0];

    if (!file) {
        callback("No file selected.");
        return;
    }

    const reader = new FileReader();

    reader.onload = function (e) {
        const csvText = e.target.result;
        const lines = csvText.split('\n');
        const headers = lines[0].split(',');
        const jsonData = [];

        for (let i = 1; i < lines.length; i++) {
            const values = lines[i].split(',');
            const item = {};

            for (let j = 0; j < headers.length; j++) {
                item[headers[j]] = values[j];
            }

            jsonData.push(item);
        }

        let data = jsonData.map(el => {
            let arr = Object.entries(el);
            let [id, locationName, fee, time, minOrder, isAvailable] = [
                arr.find(el => haveTerm('id', el[0]))[1],
                arr.find(el => haveTerm('locationName', el[0]))[1],
                Number(arr.find(el => haveTerm('fee', el[0]))[1]),
                arr.find(el => haveTerm('time', el[0]))[1],
                Number(arr.find(el => haveTerm('minOrder', el[0]))[1]),
                haveTerm('true', arr.find(el => haveTerm('isAvailable', el[0]))[1]),
            ];
            console.log({
                id: id,
                locationName: locationName,
                fee: fee,
                time: time,
                minOrder: minOrder,
                isAvailable: isAvailable
            });
            return {
                id: id,
                locationName: locationName,
                fee: fee,
                time: time,
                minOrder: minOrder,
                isAvailable: isAvailable
            }
        }).filter(el => {
            return !isNaN(el.fee) && !isNaN(el.minOrder) && String(el.id).length > 0 && String(el.time).length > 0 && String(el.idlocationName).length > 0
        });
        DeliveryPriceService.Import(data).then(r => {
            updateData();
        });
        callback(null, jsonData);
    };

    reader.onerror = function (e) {
        callback("Error reading the file.");
    };

    reader.readAsText(file);
}


export default function DeliveryPrice() {


    let [area, setArea] = useState({
        "id": "",
        "locationName": "",
        "fee": 0,
        "time": "",
        "minOrder": 0,
        "isAvailable": null
    });

    const [data, setData] = useState([]);

    const updateData = () => {

        DeliveryPriceService.GetAll().then(r => {

            let data = r.map(i => {

                return i;
            });
            setData(data);


        });
    };
    useEffect(updateData, []);
    useEffect(() => {
        if (data.length > 0) {
            $('#dataTable').DataTable().destroy();
            $('#dataTable').DataTable();
        }
        /* $(".dropdown-menu li a").click(function(){
   
             $(".btn:first-child").html($(this).text()+' <span className="caret"></span>');
             
 
             
           });*/

     //      $(function(){
      //      $('#datepicker').datepicker();
       //   });

    }, [data])

    return <>
        <div id="wrapper">

            <SideBar />

            <div id="content-wrapper" className="d-flex flex-column">

                <div id="content">

                    <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">

                        <form className="form-inline">
                            <button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3">
                                <i className="fa fa-bars"></i>
                            </button>
                        </form>


                        <Options />

                    </nav>


                    <div className="container-fluid">

                        <h1 className="h3 mb-2 text-gray-800">Coverage Area</h1>
                        <div style={{ margin: '10px' }}>
                            <button type="button" className="btn btn-outline-success" onClick={e => $('#exportFileInput').click()} style={{ margin: '10px', padding: '10px' }}>Import Excel Sheet </button>
                            <button type="button" className="btn btn-outline-danger" onClick={() => {
                                importAres(data);
                            }} style={{ margin: '10px', padding: '10px' }}>Export Excel Sheet</button>
                            <input id="exportFileInput" type="file" className="d-none" onChange={function () {
                                exportAreas(document.getElementById('exportFileInput'), function (error, jsonData) {
                                }, updateData)
                            }} />

                        </div>
                        <div className="container" style={{maxWidth : '2720px'}}>
                            <div className="row">
                                <div className="col-2">

                                    <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" id="basic-addon1">Area Name</span>
                                        </div>
                                        <input type="text" className="form-control" placeholder="Area Name" aria-label="Area_Name" aria-describedby="basic-addon1" />
                                    </div>

                                </div>
                                <div className="col-2">
                                    <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" id="basic-addon1">Fee</span>
                                        </div>
                                        <input type="text" className="form-control" placeholder="Fee $ " aria-label="Fee" aria-describedby="basic-addon1" />
                                    </div>
                                </div>
                              
                                <div className="col-2">
                                <div className="input-group date" id="datepicker">
                                                    <input type="text" className="form-control" id="date" />
                                                    <span className="input-group-append">
                                                        <span className="input-group-text bg-light d-block">
                                                            <i className="fa fa-calendar"></i>
                                                        </span>
                                                    </span>
                                                </div>
                                </div>
                                <div className="col-2" style={{marginTop : '10px'}}>
                                    <div className="custom-control custom-switch">
                                        
                                        <input type="checkbox" className="custom-control-input" id="customSwitch1" />
                                        <label className="custom-control-label" for="customSwitch1">Turn off</label>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                        <div className="card shadow mb-4">

                            <div className="card-header py-3">

                                <div className="row">


                                    <div className="col-6 d-flex align-items-center">
                                        <h6 className="m-0 font-weight-bold text-primary">Coverage Area</h6>
                                    </div>
                                    {/* <div className="col-6 d-flex justify-content-end align-items-center">
                                        <div className="custom-control custom-switch">
                                            <input type="checkbox" className="custom-control-input" id="SwitcheIsAvaliable" />
                                            <label style={{ marginRight: '20px' }} className="custom-control-label" for="SwitcheIsAvaliable">Is Avaliable</label>
                                        </div>
                                        <button type="button" className="btn btn-outline-warning">Get All</button>


                                    </div> */}
                                </div>



                            </div>
                            <div className="card-body">
                                <div className="table-responsive">

                                    <table className="table table-bordered" id="dataTable" width="100%" cellSpacing="0">
                                        <thead>
                                            <tr>
                                                <th>Area Name</th>
                                                <th>Fee</th>
                                                <th>Time</th>
                                                <th>Min Order amount</th>
                                                <th>Turn on / Turn off</th>
                                                <th>Update</th>
                                            </tr>
                                        </thead>
                                        <tfoot>
                                            <tr>
                                                <th>Area Name</th>
                                                <th>Fee</th>
                                                <th>Time</th>
                                                <th>Min Order amount</th>
                                                <th>Turn on / Turn off</th>
                                                <th>Update</th>

                                            </tr>
                                        </tfoot>
                                        <tbody>

                                            {
                                                data.map(d => <tr>
                                                    <td>{d.locationName}</td>
                                                    <td>{d.fee} JD</td>
                                                    <td>{d.time} min</td>
                                                    <td>{d.minOrder} JD</td>
                                                    <td>
                                                        <div className="custom-control custom-switch">
                                                            <input type="checkbox" className="custom-control-input" id={"customSwitches" + d.id} defaultChecked={d.isAvailable} onChange={e => {
                                                                DeliveryPriceService.Update({ ...d, isAvailable: e.target.checked });
                                                            }} />
                                                            <label className="custom-control-label" for={"customSwitches" + d.id}></label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <button type="button" className="btn btn-outline-primary"
                                                            data-toggle="modal" data-target="#exampleModal"
                                                            data-whatever="@mdo" onClick={e => {
                                                                setArea(d);
                                                            }}>Update</button>
                                                    </td>
                                                </tr>)
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>



                        <div className="modal fade" id="exampleModal" tabIndex="-1" role="dialog"
                            aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="exampleModalLabel">Update Data</h5>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>

                                    <div className="modal-body">
                                        <div className="input-group mb-3">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text" id="inputGroup-sizing-default">Area
                                                    Name</span>
                                            </div>
                                            <input type="text" className="form-control" aria-label="Default"
                                                aria-describedby="inputGroup-sizing-default" value={area.locationName} onChange={e => setArea({ ...area, locationName: e.target.value })} />
                                        </div>



                                        <div className="input-group mb-3">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text" id="inputGroup-sizing-default">Fee</span>
                                            </div>
                                            <input type="text" className="form-control" aria-label="Default"
                                                aria-describedby="inputGroup-sizing-default" value={area.fee} onChange={e => setArea({ ...area, fee: Number(e.target.value) })} />
                                        </div>


                                        <div className="input-group mb-3">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text">Time</span>
                                            </div>
                                            <input type="text" className="form-control" aria-label="Amount (to the nearest dollar)" value={area.time} onChange={e => setArea({ ...area, time: e.target.value })} />
                                            <div className="input-group-append">
                                                <span className="input-group-text">H</span>
                                            </div>
                                        </div>




                                        <div className="input-group mb-3">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text">Min Order amount</span>
                                            </div>
                                            <input type="text" className="form-control" aria-label="Amount (to the nearest dollar)" value={area.minOrder} onChange={e => setArea({ ...area, minOrder: Number(e.target.value) })} />
                                            <div className="input-group-append">
                                                <span className="input-group-text">JD</span>
                                            </div>
                                        </div>


                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                            <button type="button" className="btn btn-primary" onClick={e => {
                                                DeliveryPriceService.Update(area).then(r => {
                                                    window.location.reload();
                                                    // if(r.data.code == 200) {

                                                    // } else {

                                                    // } 
                                                })
                                            }}>Update</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>

                    <footer className="sticky-footer bg-white">
                        <div className="container my-auto">
                            <div className="copyright text-center my-auto">
                                <span>Copyright &copy; FUAIS 2023</span>
                            </div>
                        </div>
                    </footer>

                </div>

            </div>
        </div>

        <a className="scroll-to-top rounded" href="#page-top">
            <i className="fas fa-angle-up"></i>
        </a>

        <div className="modal fade" id="logoutModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel"
            aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Ready to Leave?</h5>
                        <button className="close" type="button" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body">Select "Logout" below if you are ready to end your current session.</div>
                    <div className="modal-footer">
                        <button className="btn btn-secondary" type="button" data-dismiss="modal">Cancel</button>
                        <a className="btn btn-primary" href="login.html">Logout</a>
                    </div>
                </div>
            </div>
        </div>
    </>;
}