import { useEffect, useRef, useState } from "react";
import RestaurantService from "../../Services/RestaurantService";



export default function Options() {



    const nameRef = useRef();
    const imageRef = useRef();
    useEffect(() => {
        RestaurantService.GetProfileRestaurant().then(
            r => {
                nameRef.current.innerText = r[0].name;
                imageRef.current.src = r[0].image;
            }
        );
    }, []);
    return <ul className="navbar-nav ml-auto">
        <div className="topbar-divider d-none d-sm-block"></div>
        <li className="nav-item dropdown no-arrow">
            <a className="nav-link dropdown-toggle" href="#" id="userDropdown" role="button"
                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <span className="mr-2 d-none d-lg-inline text-gray-600 small" ref={nameRef}></span>
                <img className="img-profile rounded-circle" src="img/undraw_profile.svg" ref={imageRef} />
            </a>
            <div className="dropdown-menu dropdown-menu-right shadow animated--grow-in"
                aria-labelledby="userDropdown">
                <a className="dropdown-item" href="#">
                    <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                    Profile
                </a>
                <a className="dropdown-item" href="#">
                    <i className="fas fa-cogs fa-sm fa-fw mr-2 text-gray-400"></i>
                    Settings
                </a>
                <a className="dropdown-item" href="#">
                    <i className="fas fa-list fa-sm fa-fw mr-2 text-gray-400"></i>
                    Activity Log
                </a>
                <div className="dropdown-divider"></div>
                <button className="dropdown-item" data-toggle="modal" data-target="#logoutModal" onClick={e => {
                    window.localStorage.setItem('accessToken', '');
                    window.location.reload();
                }}>
                    <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                    Logout
                </button>
            </div>
        </li>
    </ul>;

}