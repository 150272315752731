import { Service } from "./Service";
import axios from "axios";

class ComplaintService extends Service {
    name = 'Complaint';
    async GetComplaints() {
        let result = [];
        let r = await axios.post(this.baseURL + this.name + '/GetComplaints?skip=0&take=100', {},this.config);
        console.log(r);
        if (r.status == 200) {
            result = r.data.result;
        }
        return result;
    }


    async ChangeComplaintsStatus(obj) {

        let r = await axios.post(this.baseURL + this.name + '/ChangeComplaintsStatus', obj,this.config);
        console.log(r);
        if (r.status == 200) {

        }
    }
}


export default new ComplaintService();