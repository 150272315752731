import { useEffect, useRef, useState } from "react";
import OrderService from "../../Services/OrderService";
import SideBar from "../common/SideBar";
import Options from "../common/Options";


const $ = window.jQuery;

export default function Order() {

    const orderStatusArr = ['', 'New', 'Accepted', 'Prepared', 'Finished', 'Rejected'];
    const paymentStatusArr = ['', 'Paid', 'Unpaid'];

    const [filter, setFilter] = useState({
        PageIndex: 0,
        PageSize: 100
    });

    const updateFilter = (newFilter) => {
        setFilter(newFilter);
    };

    const updateData = () => {
        OrderService.GetOrdersReport(filter).then(r => {
            let data = r.map(i => {
                return i;
            });
            setData(data);


        });
    };

    const [data, setData] = useState([]);
    useEffect(updateData, [filter]); // Add filter as a dependency to the useEffect hook
    useEffect(() => {
        if (data.length != 0) {
            $('#ComplaintDataTable').DataTable()
        }
    }, [data]);

    return <div id="wrapper">

        <SideBar />


        <div id="content-wrapper" className="d-flex flex-column">

            <div id="content">

                <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">

                    <form className="form-inline">
                        <button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3">
                            <i className="fa fa-bars"></i>
                        </button>
                    </form>

                    <Options />

                </nav>

                <div className="container-fluid">

                    <h1 className="h3 mb-2 text-gray-800">Orders Report</h1>

                    {/* <FilterComponent onUpdate={updateFilter} /> Add your filter component and pass the updateFilter function as a prop */}

                    <div className="card shadow mb-4">
                        <div className="card-header py-3">
                            <div className="row">
                                <div className="col-6 d-flex align-items-center">
                                    <h6 className="m-0 font-weight-bold text-primary">Orders Report</h6>
                                </div>
                                <div className="col-6 d-flex justify-content-end">

                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive">

                                <table className="table table-bordered" id="ComplaintDataTable" width="100%" cellSpacing="0">
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Order # </th>
                                            <th>Order Status</th>
                                            <th>Payment Status</th>
                                            <th>Branche Name</th>
                                            <th>Total</th>
                                        </tr>
                                    </thead>
                                    <tfoot>
                                        <tr>
                                            <th>Name</th>
                                            <th>Order # </th>
                                            <th>Order Status</th>
                                            <th>Payment Status</th>
                                            <th>Branche Name</th>
                                            <th>Total</th>
                                        </tr>
                                    </tfoot>
                                    <tbody>
                                        {
                                            data.map(i => {
                                                return <tr id={i.id}>
                                                    <td>{i.name}</td>
                                                    <td>{i.orderNo}</td>
                                                    {/* <td>{orderStatusArr[i.orderStatus]}</td> */}
                                                    <td>
                                                        <select className="form-control" onChange={e => {
                                                            console.log(i);
                                                            [
                                                                () => {},
                                                                () => {},
                                                                OrderService.AcceptOrder,
                                                                OrderService.PreparedOrder,
                                                                OrderService.FinishOrder,
                                                                OrderService.RejectOrder
                                                            ][e.target.value].bind(OrderService)(i.id);
                                                        }}>
                                                            {
                                                                orderStatusArr.map((os, index) => <option value={index} selected={index == i.orderStatus}>
                                                                    {os}
                                                                </option>)
                                                            }
                                                        </select>
                                                    </td>
                                                    <td>{paymentStatusArr[i.paymentStatus]}</td>
                                                    <td>{i.brancheName}</td>
                                                    <td>{i.total} JD</td>
                                                </tr>
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                </div>

            </div>

            <footer className="sticky-footer bg-white">
                <div className="container my-auto">
                    <div className="copyright text-center my-auto">
                        <span>Copyright &copy; FUAIS 2023</span>
                    </div>
                </div>
            </footer>

        </div>

    </div>
}
