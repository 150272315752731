import logo from './logo.svg';
import './App.css';
import Login from './Components/Login';
import { HashRouter, Route, Routes } from 'react-router-dom';
import { useEffect } from 'react';
import Alert from './Components/common/Alert';
import { routesList } from './constants/constants';
import UserService from './Services/UserService';
import AppContext from './Contexts/AppContext';
import { useState } from 'react';




function App() {

  const accessToken = localStorage.getItem('accessToken');
  const [user, setUser] = useState();

  useEffect(() => {
    // start Dropdown branch status 
    var branchStatusDropdownItems = document.querySelectorAll("#dropdownBranchStatus li a");

    branchStatusDropdownItems.forEach(function (item) {
      item.addEventListener("click", function () {
        var textBtnDrop = document.querySelector(".textBtnDrop:first-child");
        textBtnDrop.innerHTML = this.textContent + ' <span class="caret"></span>';
      });
    });
    // End Dropdown branch status

    // start Dropdown Days 
    var daysDropdownItems = document.querySelectorAll("#dropdownDays li a");

    daysDropdownItems.forEach(function (item) {
      item.addEventListener("click", function () {
        var textBtnDropdownDays = document.querySelector(".textBtnDropdownDays:first-child");
        textBtnDropdownDays.innerHTML = this.textContent + ' <span class="caret"></span>';
      });
    });
    // End Dropdown Days


    UserService.GetCurrentUser().then(r => {
      console.log(r);
      setUser(r[0]);
    });
  }, []);


  return <AppContext.Provider value={{ user }}>
    {
      accessToken != null && accessToken != 'null' && accessToken != 'undefined' && accessToken != '' ? <HashRouter>
        <Routes>
          {routesList
          .filter(r => {
            if (r.privilege == '') {
              return true;
            }
            let routePrivileges = r.privilege.split(',');
            let userPrivileges = user?.privilege?.map(up => up.previligName);

            if (userPrivileges?.find(up => routePrivileges.includes(up))) {
              return true;
            }
            return false;
          })
          .map(r => <Route path={r.path} element={r.element}></Route>)}
        </Routes>
      </HashRouter> : <Login />
    }
    <Alert />
  </AppContext.Provider>;
}

export default App;
