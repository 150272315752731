import { Link } from "react-router-dom";
import { UserContext } from './../../Contexts/UserContext';
import { useContext } from "react";
import AppContext from "../../Contexts/AppContext";





export default function SideBar() {


    const { user } = useContext(AppContext);
    const privileges = user?.privilege?.map(p => p.previligName)??[];
    console.log(privileges);
    return <ul className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">
        <a className="sidebar-brand d-flex align-items-center justify-content-center" href="index.html">
            <div className="sidebar-brand-icon rotate-n-15">
                <i className="fas fa-pizza-slice" style={{ color: "#ffffff" }}></i>
            </div>
            <div className="sidebar-brand-text mx-3">
                بيتزا القيصر </div>
        </a>

        <div className="sidebar-heading">View</div>
        <hr className="sidebar-divider my-0" />
        <li className="nav-item">
            <Link to='/' className="nav-link">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                    className="bi bi-speedometer2" viewBox="0 0 16 16">
                    <path
                        d="M8 4a.5.5 0 0 1 .5.5V6a.5.5 0 0 1-1 0V4.5A.5.5 0 0 1 8 4zM3.732 5.732a.5.5 0 0 1 .707 0l.915.914a.5.5 0 1 1-.708.708l-.914-.915a.5.5 0 0 1 0-.707zM2 10a.5.5 0 0 1 .5-.5h1.586a.5.5 0 0 1 0 1H2.5A.5.5 0 0 1 2 10zm9.5 0a.5.5 0 0 1 .5-.5h1.5a.5.5 0 0 1 0 1H12a.5.5 0 0 1-.5-.5zm.754-4.246a.389.389 0 0 0-.527-.02L7.547 9.31a.91.91 0 1 0 1.302 1.258l3.434-4.297a.389.389 0 0 0-.029-.518z" />
                    <path fillRule="evenodd"
                        d="M0 10a8 8 0 1 1 15.547 2.661c-.442 1.253-1.845 1.602-2.932 1.25C11.309 13.488 9.475 13 8 13c-1.474 0-3.31.488-4.615.911-1.087.352-2.49.003-2.932-1.25A7.988 7.988 0 0 1 0 10zm8-7a7 7 0 0 0-6.603 9.329c.203.575.923.876 1.68.63C4.397 12.533 6.358 12 8 12s3.604.532 4.923.96c.757.245 1.477-.056 1.68-.631A7 7 0 0 0 8 3z" />
                </svg>
                <span>Dahboard</span></Link>
        </li>

        {
            true ? <li className="nav-item">
                <Link to="/EditProfile" className="nav-link">
                    <i className="fa-solid fa-user" style={{ color: "#ffffff" }}></i>
                    <span>Profile</span></Link>
            </li> : <></>
        }

        {
            privileges.includes('Admin') ? <li className="nav-item">
                <Link to="/Menu" className="nav-link">
                    <i className="fa-solid fa-utensils" style={{ color: "#ffffff" }}></i>
                    <span>Menu</span></Link>
            </li> : <></>
        }

        {
            privileges.includes('Admin') ? <li className="nav-item">
                <Link to="/ExtraSection" className="nav-link">
                    <i className="fa-solid fa-utensils" style={{ color: "#ffffff" }}></i>
                    <span>Extra sections</span></Link>
            </li> : <></>
        }

        {
            privileges.includes('Admin') ? <li className="nav-item">
                <Link to="/BranchStatus" className="nav-link">
                    <i className="fa-solid fa-shop" style={{ color: "#ffffff" }}></i>
                    <span>Branch Status</span></Link>
            </li> : <></>
        }

        {
            privileges.includes('Admin') ? <li className="nav-item">
                <Link to="/CoverageArea" className="nav-link">
                    <i className="fa-solid fa-map-pin" style={{ color: "#ffffff" }}></i>
                    <span>Converage Area</span></Link>
            </li> : <></>
        }

        {
            privileges.includes('Admin') ? <li className="nav-item">
                <Link to="/UserManagment" className="nav-link">
                    <i className="fa-solid fa-user-gear" style={{ color: "#ffffff" }}></i>
                    <span>User Management</span></Link>
            </li> : <></>
        }

        {
            privileges.includes('Admin') || privileges.includes('Branch') ? <li className="nav-item">
                <Link to="/Order" className="nav-link">
                    <i className="fa-solid fa-burger" style={{ color: "#ffffff" }}></i>
                    <span>Orders</span></Link>
            </li> : <></>
        }


        {
            privileges.includes('Admin') || privileges.includes('Branch') ? <li className="nav-item">
                <Link to="/Complaint" className="nav-link">
                    <i className="fa-solid fa-circle-exclamation" style={{ color: "#ffffff" }}></i>
                    <span>Complaint Management</span></Link>
            </li> : <></>
        }

        {/* <li className="nav-item">
            <Link className="nav-link">
                <i className="fa-solid fa-money-bill-1-wave" style={{ color: "#ffffff" }}></i>
                <span>Loyalty point</span></Link>
        </li> */}


        <li className="nav-item">
            <a className="nav-link">
                <i className="fa-solid fa-arrow-right-from-bracket" style={{ color: "#ffffff" }} onClick={e => {
                    window.localStorage.setItem('accessToken', '');
                    window.location.reload();
                }}></i>
                <span>Sing Out</span></a>
        </li>

        <hr className="sidebar-divider" />

        <div className="text-center d-none d-md-inline">
            <button className="rounded-circle border-0" id="sidebarToggle"></button>
        </div>
    </ul>;
}