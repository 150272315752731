import { useRef } from "react";

export default function Alert() {

    const ref = useRef();
    const showAlert = (show) => {
        ref.current.style.display = show? 'block': 'none';
    }; 

    return <div ref={ref} className="modal fade show" id="alertModal" tabIndex="-1" aria-labelledby="alertModalLabel" style={{
        display: 'none'
    }}
        aria-modal="true" role="dialog">
        <form className="modal-dialog" role="document">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="alertModalLabel">Update Data</h5><button type="button" className="close"
                        data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
                </div>
                <div className="modal-body">
                    Modal body
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary"
                        data-dismiss="modal">Close</button>
                    <button type="submit" className="btn btn-primary">Update</button>
                </div>
            </div>
        </form>
    </div>;
}