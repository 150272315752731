import { useEffect, useRef, useState } from "react";
import RestaurantBrancheService from "../../Services/RestaurantBrancheService";
import SideBar from "../common/SideBar";
import Options from "../common/Options";
import RestaurantService from "../../Services/RestaurantService";
import Swal from "sweetalert2";
import OrderService from "../../Services/OrderService";
import UserService from "../../Services/UserService";
import Layout from "../common/Layout";
import { Link } from "react-router-dom";
import { UserContext } from "../../Contexts/UserContext";
import PrivilegeService from "../../Services/PrivilegeService";

const UserPrivileges = ({ allPrivileges = [], userPrivileges = [] }) => {
    const [grantedPrivileges, setGrantedPrivileges] = useState(userPrivileges);



    return <div>
        <div className="d-inline-block">
            {
                grantedPrivileges.map(gp => <button className="btn btn-outline-primary rounded-pill">{gp.previligName}</button>)
            }
        </div>
        <div className="mx-5 d-inline-block">
            {/* <select className="form-control">
                {
                    allPrivileges
                        .filter(ap => !grantedPrivileges.find(gp => gp == ap))
                        .map(ap => <option onClick={e => {
                            
                        }}>{ap.previligName}</option>)
                }
            </select> */}
        </div>
    </div>;
}


export default function UserManagment() {
    let data = [];
    let update = () => { };


    const [users, setUsers] = useState([]);

    const getUsers = () => {
        UserService.GetAll().then(r => {
            console.log(r);
            setUsers(r);
        });
    }





    const [allPrivileges, setAllPrivileges] = useState([]);

    const getAllPrivileges = () => {
        PrivilegeService.GetAll().then(r => {
            console.log(r);
            setAllPrivileges(r);
        });
    }


    useEffect(() => {
        getUsers();
        getAllPrivileges();
    }, []);


    return <Layout>
        <div className="container-fluid">
            <h1 className="h3 mb-2 text-gray-800">User managment</h1>
            <div className="card shadow mb-4">
                <div className="card-header py-3">
                    <div className="row">
                        <div className="col-6 d-flex align-items-center">
                            <h6 className="m-0 font-weight-bold text-primary">User managment</h6>
                        </div>
                        <div className="col-6 d-flex justify-content-end">

                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <div className="table-responsive">
                        <table className="table table-bordered" id="dataTable" width="100%" cellSpacing="0">
                            <thead>
                                <tr>
                                    <th>User name</th>
                                    <th>Privileges</th>
                                    {/* <th>Edit</th> */}
                                </tr>
                            </thead>
                            <tfoot>
                                <tr>
                                    <th>User name</th>
                                    <th>Privileges</th>
                                    {/* <th>Edit</th> */}
                                </tr>
                            </tfoot>
                            <tbody>
                                {users.map(user => <tr>
                                    <td>{user.userName}</td>
                                    <td>
                                        <UserPrivileges userPrivileges={user.privilege} allPrivileges={allPrivileges} />
                                    </td>
                                </tr>)}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </Layout>;
}