import { useEffect, useRef, useState } from "react";
import RestaurantService from "../../Services/RestaurantService";
import Options from "../common/Options";
import SideBar from "../common/SideBar";
import Swal from "sweetalert2";



export default function EditProfile() {

    const [data, setData] = useState({
        "name": "",
        "nameTranslations": "",
        "description": "",
        "descriptionTranslations": "",
        "phoneNumber": "",
        "email": "",
        "website": "",
        "facebook": null,
        "whatsapp": null,
        "instagram": null,
        "tikTok": null,
        "youTube": null,
        "snapchat": null,
        "twitter": null,
        "foodPrepTime": null,
        "image": "",
        "imageCover": "",
        "userName": "",
        "colorPrimary": "",
        "colorSecondary": "",
        "colorTertiary": "",
        "designType": 0,
        "expiryDateLicense": "",
        "numberRestaurantBranches": 0,
        "onlinePayment": false,
        "delivery": false,
        "pickup": false,
        "dineIn": false,
        "costPerKm": 0,
        "minCost": 0
    });

    const imageRef = useRef();
    //const coverImageRef = useRef();



    useEffect(() => {
        RestaurantService.GetProfileRestaurant().then(
            r => {
                console.log(r[0]);
                setData(r[0]);
            }
        );
    }, []);
    return <>
        <div id="wrapper">
            <SideBar />
            <div id="content-wrapper" className="d-flex flex-column">
                <div id="content">
                    <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
                        <button type="button" className="btn btn-success" style={{ width: '140px' }} onClick={e => {
                            console.log(data);
                            let formData = new FormData();
                            formData.append("name", data.name);
                            formData.append("nameTranslations", data.nameTranslations);
                            formData.append("description", data.description);
                            formData.append("descriptionTranslations", data.descriptionTranslations);
                            formData.append("phoneNumber", data.phoneNumber);
                            formData.append("email", data.email);
                            formData.append("website", data.website);
                            formData.append("facebook", data.facebook);
                            formData.append("whatsapp", data.whatsapp);
                            formData.append("instagram", data.instagram);
                            formData.append("tikTok", data.tikTok);
                            formData.append("youTube", data.youTube);
                            formData.append("snapchat", data.snapchat);
                            formData.append("twitter", data.twitter);
                            formData.append("foodPrepTime", data.foodPrepTime);
                            formData.append("image", imageRef.current.files[0]);
                            //formData.append("imageCover", data.imageCover);
                            formData.append("userName", data.userName);
                            formData.append("colorPrimary", data.colorPrimary);
                            formData.append("colorSecondary", data.colorSecondary);
                            formData.append("colorTertiary", data.colorTertiary);
                            formData.append("designType", data.designType);
                            formData.append("expiryDateLicense", data.expiryDateLicense);
                            formData.append("numberRestaurantBranches", data.numberRestaurantBranches);
                            formData.append("onlinePayment", data.onlinePayment);
                            formData.append("delivery", data.delivery);
                            formData.append("pickup", data.pickup);
                            formData.append("dineIn", data.dineIn);
                            formData.append("costPerKm", data.costPerKm);
                            formData.append("minCost", data.minCost);
                            RestaurantService.ChangeProfileRestaurant(formData).then(r => {
                                console.log(r);
                                if (r?.data?.code == 200) {
                                    Swal.fire({
                                        title: 'Profile updated',
                                        text: 'Profile updated successfully',
                                        icon: 'success',
                                        confirmButtonText: 'Ok'
                                    }).then(a => {
                                           
                                    })
                                } else {
                                    Swal.fire({
                                        title: 'Failed',
                                        text: 'Profile updated failed',
                                        icon: 'error',
                                        confirmButtonText: 'Ok'
                                    }).then(a => {
                                           
                                    })
                                }
                            });

                        }}>Save</button>
                        <form className="form-inline">
                            <button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3">
                                <i className="fa fa-bars"></i>
                            </button>
                        </form>
                        <Options />
                       
                    </nav>
                    <div className="container-fluid">
                        <h1 className="h3 mb-2 text-gray-800">Profile</h1>
                        <div className="text-center">
                            <img src={data.image||'img/undraw_profile.svg'}
                                style={{ margin: '15px' }} className="rounded float-left" alt="..." width="120px" />

                        </div>
                        <div className="input-group mb-3">
                            <div className="input-group-prepend">
                                <span className="input-group-text">Upload Profile image </span>
                            </div>
                            <div className="custom-file">
                                <input type="file" className="custom-file-input" id="inputGroupFile01" ref={imageRef} onChange={function (e) {
                                    
                                    if (imageRef.current.files && imageRef.current.files[0]) {
                                        const reader = new FileReader();

                                        // Set up a callback for when the file is loaded
                                        reader.onload = function (e) {
                                            // Update the src attribute of the image with the loaded data
                                            setData({ ...data, image: e.target.result });
                                        };

                                        // Read the selected file as a data URL
                                        reader.readAsDataURL(imageRef.current.files[0]);
                                    } else {
                                        // Clear the image source if no file is selected
                                        setData({ ...data, image: '' });
                                    }

                                }} />
                                <label className="custom-file-label" for="inputGroupFile01">Choose file</label>
                            </div>
                        </div>
                        <hr />
                        <label className="form-label" for="customFile">Info</label>
                        <div className="input-group mb-3">
                            <div className="input-group-prepend">
                                <span className="input-group-text">Name</span>
                            </div>
                            <input type="text" className="form-control" placeholder="Name" aria-label="Username"
                                aria-describedby="basic-addon1" value={data.name} onChange={(e) => setData({ ...data, name: e.target.value })} />
                        </div>
                        {/* <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text">Description</span>
                            </div>
                            <textarea className="form-control" aria-label="Description" value={data.description}></textarea>
                        </div> */}
                        <hr />
                        <div className="input-group mb-3">
                            <div className="input-group-prepend">
                                <span className="input-group-text">Cost per KM</span>
                            </div>
                            <input type="text" className="form-control" placeholder="Cost per KM" aria-label="Username"
                                aria-describedby="basic-addon1" value={data.costPerKm} onChange={(e) => setData({ ...data, costPerKm: e.target.value })} />
                        </div>
                        <div className="input-group mb-3">
                            <div className="input-group-prepend">
                                <span className="input-group-text">Min Cost</span>
                            </div>
                            <input type="text" className="form-control" placeholder="Min Cost" aria-label="Username"
                                aria-describedby="basic-addon1" value={data.minCost} onChange={(e) => setData({ ...data, minCost: e.target.value })} />
                        </div>
                        <div className="custom-control custom-switch">
                            <input type="checkbox" className="custom-control-input" id="customSwitches" checked={data.onlinePayment} onChange={(e) => setData({ ...data, onlinePayment: e.target.checked })} />
                            <label className="custom-control-label" for="customSwitches">online payment</label>
                        </div>
                        <div className="custom-control custom-switch">
                            <input type="checkbox" className="custom-control-input" id="customSwitches2" checked={data.delivery} onChange={(e) => setData({ ...data, delivery: e.target.checked })} />
                            <label className="custom-control-label" for="customSwitches2">Delivery</label>
                        </div>
                        <div className="custom-control custom-switch">
                            <input type="checkbox" className="custom-control-input" id="customSwitches3" checked={data.pickup} onChange={(e) => setData({ ...data, pickup: e.target.checked })} />
                            <label className="custom-control-label" for="customSwitches3">Pickip</label>
                        </div>
                        <div className="custom-control custom-switch">
                            <input type="checkbox" className="custom-control-input" id="customSwitches4" checked={data.dineIn} onChange={(e) => setData({ ...data, dineIn: e.target.checked })} />
                            <label className="custom-control-label" for="customSwitches4">Dine In</label>
                        </div>
                        <hr />
                        <label className="form-label" for="customFile">Name Translation</label>
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text">Description in English</span>
                            </div>
                            <textarea className="form-control" aria-label="Description" value={data.description} onChange={(e) => setData({ ...data, description: e.target.value })}></textarea>
                        </div>
                        <br />
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text">Description in Arabic</span>
                            </div>
                            <textarea className="form-control" aria-label="Description" value={data.descriptionTranslations} onChange={(e) => setData({ ...data, descriptionTranslations: e.target.value })}></textarea>
                        </div>
                        <hr />
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">Phone number</span>
                                    </div>
                                    <input type="text" className="form-control" placeholder="Phone number" aria-label="Username"
                                        aria-describedby="basic-addon1" value={data.phoneNumber} onChange={(e) => setData({ ...data, phoneNumber: e.target.value })} />
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">Email</span>
                                    </div>
                                    <input type="text" className="form-control" placeholder="Email" aria-label="Username"
                                        aria-describedby="basic-addon1" value={data.email} onChange={(e) => setData({ ...data, email: e.target.value })} />
                                </div>
                            </div>
                            <div className="w-100"></div>
                            <div className="col-sm-6">
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">Website</span>
                                    </div>
                                    <input type="text" className="form-control" placeholder="Website" aria-label="Username"
                                        aria-describedby="basic-addon1" value={data.website} onChange={(e) => setData({ ...data, website: e.target.value })} />
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">Facebook</span>
                                    </div>
                                    <input type="text" className="form-control" placeholder="Facebook" aria-label="Username"
                                        aria-describedby="basic-addon1" value={data.facebook} onChange={(e) => setData({ ...data, facebook: e.target.value })} />
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">Whatsapp</span>
                                    </div>
                                    <input type="text" className="form-control" placeholder="Whatsapp" aria-label="Username"
                                        aria-describedby="basic-addon1" value={data.whatsapp} onChange={(e) => setData({ ...data, whatsapp: e.target.value })} />
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">Instagram</span>
                                    </div>
                                    <input type="text" className="form-control" placeholder="Instagram" aria-label="Username"
                                        aria-describedby="basic-addon1" value={data.instagram} onChange={(e) => setData({ ...data, instagram: e.target.value })} />
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">Tik Tok</span>
                                    </div>
                                    <input type="text" className="form-control" placeholder="Tik Tok" aria-label="Username"
                                        aria-describedby="basic-addon1" value={data.tikTok} onChange={(e) => setData({ ...data, tikTok: e.target.value })} />
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">Youtube</span>
                                    </div>
                                    <input type="text" className="form-control" placeholder="Youtube" aria-label="Username"
                                        aria-describedby="basic-addon1" value={data.youTube} onChange={(e) => setData({ ...data, youTube: e.target.value })} />
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">Snap chat</span>
                                    </div>
                                    <input type="text" className="form-control" placeholder="Snap chat" aria-label="Username"
                                        aria-describedby="basic-addon1" value={data.snapchat} onChange={(e) => setData({ ...data, snapchat: e.target.value })} />
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">Twitter</span>
                                    </div>
                                    <input type="text" className="form-control" placeholder="Twitter" aria-label="Username"
                                        aria-describedby="basic-addon1" value={data.twitter} onChange={(e) => setData({ ...data, twitter: e.target.value })} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <footer className="sticky-footer bg-white">
                    <div className="container my-auto">
                        <div className="copyright text-center my-auto">
                            <span>Copyright &copy; FUAIS 2023</span>
                        </div>
                    </div>
                </footer>

            </div>

        </div>

        <a className="scroll-to-top rounded" href="#page-top">
            <i className="fas fa-angle-up"></i>
        </a>

        <div className="modal fade" id="logoutModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel"
            aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Ready to Leave?</h5>
                        <button className="close" type="button" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body">Select "Logout" below if you are ready to end your current session.</div>
                    <div className="modal-footer">
                        <button className="btn btn-secondary" type="button" data-dismiss="modal">Cancel</button>
                        <a className="btn btn-primary" href="login.html">Logout</a>
                    </div>
                </div>
            </div>
        </div>
    </>;

}