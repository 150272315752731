import { useNavigate } from "react-router-dom";

export default class Controller {


    navigator = null;

    reducer = () => {};

    state = {};

    main = () => {};

    dispatcher = () => {};

    setDispatcher = function (dispatcher) {
        this.dispatcher = dispatcher;
    };

    formRef = null;
}