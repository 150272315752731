import { useEffect, useRef, useState } from "react";
import RestaurantBrancheService from "../../Services/RestaurantBrancheService";
import SideBar from "../common/SideBar";
import Options from "../common/Options";
import RestaurantService from "../../Services/RestaurantService";
import Swal from "sweetalert2";
import OrderService from "../../Services/OrderService";




export default function Dashboard() {


    const [data, setData] = useState([]);
    const fromDate = useRef();
    const toDate = useRef();

    const update = ({ from=null, to=null}={}) => {
        if(from) {
            fromDate.current = from; 
        }
        if(to) {
            toDate.current = to;
        }
        OrderService.GetDashboardOrdersReport(fromDate.current, toDate.current).then(r => {
            console.log(r);
            setData(r);
        });
    };
    useEffect(update, []);
    return <div id="wrapper">
        <SideBar />
        <div id="content-wrapper" className="d-flex flex-column">
            <div id="content">
                <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
                    <form className="form-inline">
                        <button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3">
                            <i className="fa fa-bars"></i>
                        </button>
                    </form>
                    <Options />
                </nav>

                <div className="container-fluid">
                    <h1 className="h3 mb-2 text-gray-800">Dashboard</h1>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xl-3 col-md-6 mb-4">
                                <div className="card border-left-success shadow h-100 py-2">
                                    <div className="card-body">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col mr-2">
                                                <div className="text-xs font-weight-bold text-success text-uppercase mb-1">
                                                    Total orders </div>
                                                <div className="h5 mb-0 font-weight-bold text-gray-800">{data.find(d => d.brancheName == 'All Branches')?.totalOrders}</div>
                                            </div>
                                            <div className="col-auto">
                                                <i className="fas fa-calendar fa-2x text-gray-300"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-md-6 mb-4">
                                <div className="card border-left-success shadow h-100 py-2">
                                    <div className="card-body">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col mr-2">
                                                <div className="text-xs font-weight-bold text-success text-uppercase mb-1">
                                                    Total sales </div>
                                                <div className="h5 mb-0 font-weight-bold text-gray-800">{data.find(d => d.brancheName == 'All Branches')?.totalSalse} JD</div>
                                            </div>
                                            <div className="col-auto">
                                                <i className="fas fa-calendar fa-2x text-gray-300"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-4">
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1">From date</span>
                                    </div>
                                    <input type="date" className="form-control" placeholder="From date" aria-label="From_Date" aria-describedby="basic-addon1" onChange={e => update({ from: e.target.value })} />
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1">To date</span>
                                    </div>
                                    <input type="date" className="form-control" placeholder="To date" aria-label="To_Date" aria-describedby="basic-addon1" onChange={e => update({ to: e.target.value })} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card shadow mb-4">
                        <div className="card-header py-3">
                            <div className="row">
                                <div className="col-6 d-flex align-items-center">
                                    <h6 className="m-0 font-weight-bold text-primary">Branch Status</h6>
                                </div>
                                <div className="col-6 d-flex justify-content-end">

                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive">

                                <table className="table table-bordered" id="dataTable" width="100%" cellSpacing="0">
                                    <thead>
                                        <tr>
                                            <th>Branch Name</th>
                                            <th>Number of orders</th>
                                            <th>Total salse</th>
                                        </tr>
                                    </thead>
                                    <tfoot>
                                        <tr>
                                            <th>Branch Name</th>
                                            <th>Number of orders</th>
                                            <th>Total salse</th>
                                        </tr>
                                    </tfoot>
                                    <tbody>
                                        {data.filter(d => d.brancheName != 'All Branches').map(d => {
                                            return <tr>
                                                <td>{d.brancheName}</td>
                                                <td>{d.totalOrders}</td>
                                                <td>{d.totalSalse} JD</td>
                                            </tr>;
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                </div>

            </div>

            <footer className="sticky-footer bg-white">
                <div className="container my-auto">
                    <div className="copyright text-center my-auto">
                        <span>Copyright &copy; FUAIS 2023</span>
                    </div>
                </div>
            </footer>

        </div>

    </div>
}