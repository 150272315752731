import axios from "axios";

export class Service {

    accessToken = localStorage.getItem('accessToken');
    config = {
        headers: {
            'Authorization': 'Bearer ' + this.accessToken,
            //'ngrok-skip-browser-warning': 'latest',
        }
    };
    configMultiPart = {
        headers: {
            'Authorization': 'Bearer ' + this.accessToken,
            'content-type': 'multipart/form-data',
            //'ngrok-skip-browser-warning': 'latest',
        }
    };
    baseURL = 'https://api.qaysarpizza.com/en-US/api/';
    //baseURL = 'https://localhost:44322/en-US/api/';
    
    name = '';
    async GetAll() {
        let result = [];
        let r = await axios.get(this.baseURL + this.name + '/GetAll', this.config);
        if (r.status == 200) {
            result = r.data.result;
        }
        return result;
    }

    async GetById(id) {
        let obj = {};
        let r = await axios.get(this.baseURL + this.name + '/GetById?id=' + id, this.config);
        if (r.status == 200) {
            obj = r.data.result;
        }
        return obj;
    }


    async Update(obj) {

        let r = await axios.post(this.baseURL + this.name + '/Update', obj, this.config);
        console.log(r);
        return r;

    }

    async Create(obj) {

        let r = await axios.post(this.baseURL + this.name + '/Create', obj, this.config);
        console.log(r);
        if (r.status == 200) {
            
        }
    }


    


}