import axios from "axios";
import { Service } from "./Service";

class PrivilegeService extends Service {
    name = 'Privilege';

    
}


export default new PrivilegeService();