import BranchStatus from '../Components/BranchStatus/BranchStatus';
import Complaint from '../Components/Complaint/Complaint';
import Order from '../Components/Orders/Order';
import Menu from '../Components/Menu/Menu';
import EditProfile from '../Components/Profile/EditProfile';
import AddEditItem from '../Components/Item/AddEditItem';
import DeliveryPrice from '../Components/DeliveryPrice/DeliveryPrice';
import ExtraSection from '../Components/ExtraSection/ExtraSection';
import Dashboard from '../Components/Dashboard/Dashboard';
import UserManagment from '../Components/UserManagment/UserManagment';
import Privileges from '../Components/UserManagment/Privileges';


const privilegesList = [];


const routesList = [
    {
        path: '/',
        element: <Dashboard />,
        privilege: ''
    },
    {
        path: '/BranchStatus',
        element: <BranchStatus />,
        privilege: 'Admin'
    },
    {
        path: '/Order',
        element: <Order />,
        privilege: 'Admin,Branch'
    },
    {
        path: '/Menu',
        element: <Menu />,
        privilege: 'Admin'
    },
    {
        path: '/ExtraSection',
        element: <ExtraSection />,
        privilege: 'Admin'
    },
    {
        path: '/Complaint',
        element: <Complaint />,
        privilege: 'Admin,Branch'
    },
    {
        path: '/EditProfile',
        element: <EditProfile />,
        privilege: ''
    },
    {
        path: '/AddEditItem/:menuId',
        element: <AddEditItem />,
        privilege: 'Admin'
    },
    {
        path: '/AddEditItem/:menuId/:itemId',
        element: <AddEditItem />,
        privilege: 'Admin'
    },
    {
        path: '/CoverageArea',
        element: <DeliveryPrice />,
        privilege: 'Admin'
    },
    {
        path: '/UserManagment',
        element: <UserManagment />,
        privilege: 'Admin'
    }
];


export {
    privilegesList,
    routesList
};