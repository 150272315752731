import axios from "axios";
import { Service } from "./Service";

class ItemtService extends Service {
    name = 'Item';


    async AddEditItem(formData) {
        let obj = {};
        let r = await axios.post(this.baseURL + this.name + '/AddEditItem', formData, this.configMultiPart);
        console.log(r);
        if (r.status == 200) {
            obj = r.data.result;
        }
        return obj;
    }
    async Delete(id) {

        let r = await axios.post(this.baseURL + this.name + '/DeleteItem', {
            id: id
        }, this.config);
        if (r.status == 200) {
            
        }
    }


}


export default new ItemtService();