import { useEffect, useRef, useState } from "react";
import RestaurantBrancheService from "../../Services/RestaurantBrancheService";
import SideBar from "../common/SideBar";
import Options from "../common/Options";
import RestaurantService from "../../Services/RestaurantService";
import Swal from "sweetalert2";


const $ = window.jQuery;


function convertToDayName(dayNumber) {

    // Array of day names
    var dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

    // Check if the input is a valid day number
    if (dayNumber >= 1 && dayNumber <= 7) {
        // Subtract 1 from the day number to get the correct index in the array
        var dayName = dayNames[dayNumber - 1];
        return dayName;
    } else {
        return "";
    }
}


function getFormValues(formId) {
    var form = document.getElementById(formId);
    var formValues = {};

    for (var i = 0; i < form.elements.length; i++) {
        var element = form.elements[i];

        // Check if the element is an input (excluding buttons)
        if (element.tagName === 'INPUT' || element.tagName === 'SELECT') {
            formValues[element.name] = element.value;
        }
    }

    // Form values are available in the 'formValues' object
    return formValues;

    // You can send the formValues object to your backend or perform any other actions
}


function Empty(value) {
    console.log(value, value == '' || value == null);
    return value == '' || value == null;
}

const InvalidSpan = ({ value }) => <span style={{ fontSize: '12px', color: 'red', display: Empty(value) ? 'block' : 'none' }}>This field is required</span>;

export default function BranchStatus() {


    const [submitAttempt, setSubmitAttempt] = useState(true);

    const dialog = useRef();
    const [data, setData] = useState([]);
    const [currentBranch, setCurrentBranch] = useState({
        "id": "",
        "code": "",
        "name": "",
        "nameTranslations": "",
        "phoneNumber": "",
        "longitude": 0.0,
        "latitude": 0.0,
        "disableOrdering": false,
        "index": 0,
        "workingHours": []
    });
    const showDialog = (show) => {
        dialog.current.style.display = show ? 'block' : 'none';
    }


    useEffect(() => {
        RestaurantBrancheService.GetAll().then(r => {
            console.log(r);
            setData(r);
            // document.getElementById('open-count').innerText = data.filter(b => b.status == 'Open').length;
            // document.getElementById('close-count').innerText = data.filter(b => b.status == 'Close').length;
            // document.getElementById('busy-count').innerText = data.filter(b => b.status == 'Busy').length;
            // document.querySelector('#dataTable tbody').innerHTML = data.map(i => `<tr>
            //     <td>${i.name}</td>
            //     <td>${i.status}</td>
            //     <td>Today ${i.opensAt} - ${i.closesAt}</td>
            //     <td>
            //         <button type="button" className="btn btn-outline-primary"
            //             data-toggle="modal" data-target="#exampleModal"
            //             data-whatever="@mdo">Update</button>
            //     </td>
            // </tr>`).join('');
            //$('#dataTable').DataTable();
        });
    }, []);
    return <div id="wrapper">

        <SideBar />


        <div id="content-wrapper" className="d-flex flex-column">

            <div id="content">

                <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">

                    <form className="form-inline">
                        <button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3">
                            <i className="fa fa-bars"></i>
                        </button>
                    </form>


                    <Options />

                </nav>

                <div className="container-fluid">

                    <h1 className="h3 mb-2 text-gray-800">Branch Status</h1>



                    <div className="container-fluid">


                        <div className="row">

                            <div className="col-xl-3 col-md-6 mb-4">
                                <div className="card border-left-success shadow h-100 py-2">
                                    <div className="card-body">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col mr-2">
                                                <div className="text-xs font-weight-bold text-success text-uppercase mb-1">
                                                    Open branches </div>
                                                <div id="open-count" className="h5 mb-0 font-weight-bold text-gray-800"></div>
                                            </div>
                                            <div className="col-auto">
                                                <i className="fas fa-calendar fa-2x text-gray-300"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="col-xl-3 col-md-6 mb-4">
                                <div className="card border-left-danger shadow h-100 py-2">
                                    <div className="card-body">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col mr-2">
                                                <div className="text-xs font-weight-bold text-danger text-uppercase mb-1">
                                                    Closed branches </div>
                                                <div id="close-count" className="h5 mb-0 font-weight-bold text-gray-800"></div>
                                            </div>
                                            <div className="col-auto">
                                                <i className="fas fa-calendar fa-2x text-gray-300"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>



                            <div className="col-xl-3 col-md-6 mb-4">
                                <div className="card border-left-warning shadow h-100 py-2">
                                    <div className="card-body">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col mr-2">
                                                <div className="text-xs font-weight-bold text-warning text-uppercase mb-1">
                                                    Busy branches </div>
                                                <div id="busy-count" className="h5 mb-0 font-weight-bold text-gray-800"></div>
                                            </div>
                                            <div className="col-auto">
                                                <i className="fas fa-calendar fa-2x text-gray-300"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>



                        </div>

                    </div>


                    <div className="card shadow mb-4">
                        <div className="card-header py-3">
                            <div className="row">
                                <div className="col-6 d-flex align-items-center">
                                    <h6 className="m-0 font-weight-bold text-primary">Branch Status</h6>
                                </div>
                                <div className="col-6 d-flex justify-content-end">

                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive">

                                <table className="table table-bordered" id="dataTable" width="100%" cellSpacing="0">
                                    <thead>
                                        <tr>
                                            <th>Branch Name</th>
                                            <th>Status </th>
                                            <th>Time</th>
                                            <th>Update</th>

                                        </tr>
                                    </thead>
                                    <tfoot>
                                        <tr>
                                            <th>Branch Name</th>
                                            <th>Status </th>
                                            <th>Time</th>
                                            <th>Update</th>

                                        </tr>
                                    </tfoot>
                                    <tbody>
                                        {data.filter(b => b.name != "CallCenter").map(i => {

                                            let workingHours = i.workingHours.find(wh => wh.dayOfWeek == (new Date()).getDay() + 1) ?? { dayIsOpen: false };
                                            let opensAt = new Date(`2023-09-05T${i.opensAt}`);
                                            let closesAt = new Date(`2023-09-05T${i.closesAt}`);
                                            let now = new Date();
                                            let isOpen = (workingHours.dayIsOpen && now >= opensAt && now <= closesAt)
                                            return {
                                                id: i.id,
                                                name: i.name,
                                                status: isOpen && i.disableOrdering ? 'Busy' : isOpen ? 'Open' : 'Close', //disableOrdering
                                                opensAt: workingHours.opensAt ?? '',
                                                closesAt: workingHours.closesAt ?? ''
                                            }
                                        }).map(d => <tr>
                                            <td>{d.name}</td>
                                            <td>{d.status}</td>
                                            <td>Today {d.opensAt} - {d.closesAt}</td>
                                            <td>
                                                <button onClick={e => {
                                                    console.log(data, d);
                                                    setCurrentBranch(data.find(e => e.id == d.id));
                                                }} type="button" className="btn btn-outline-primary" data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo">Update</button>
                                            </td>
                                        </tr>)}

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>


                    <div className="modal fade" id="exampleModal" tabIndex="-1" role="dialog"
                        aria-labelledby="exampleModalLabel" aria-hidden="false" ref={dialog}>



                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">Update Data</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <form className="row">
                                        <div className="col-6">
                                            <label>Code</label>
                                            <input name="code" className="form-control" value={currentBranch.code} onChange={e => setCurrentBranch({ ...currentBranch, code: e.target.value })} />
                                            <InvalidSpan value={currentBranch.code} />
                                        </div>
                                        <div className="col-6">
                                            <label>name</label>
                                            <input name="name" className="form-control" value={currentBranch.name} onChange={e => setCurrentBranch({ ...currentBranch, name: e.target.value })} />
                                            <InvalidSpan value={currentBranch.name} />
                                            <div data-lastpass-icon-root="true" style={{ position: 'relative !important', height: '0px  !important', width: '0px !important', float: 'left !important' }}>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <label>Name translations</label>
                                            <input name="nameTranslations" className="form-control" value={currentBranch.nameTranslations} onChange={e => setCurrentBranch({ ...currentBranch, nameTranslations: e.target.value })} />
                                            <InvalidSpan value={currentBranch.nameTranslations} />
                                        </div>
                                        <div className="col-6">
                                            <label>Phone number</label>
                                            <input name="phoneNumber" className="form-control" value={currentBranch.phoneNumber} onChange={e => setCurrentBranch({ ...currentBranch, phoneNumber: e.target.value })} />
                                            <InvalidSpan value={currentBranch.phoneNumber} />
                                        </div>
                                        <div className="col-6">
                                            <label>Longitude</label>
                                            <input name="longitude" className="form-control" value={currentBranch.longitude} onChange={e => setCurrentBranch({ ...currentBranch, longitude: Number(e.target.value) })} />
                                            <InvalidSpan value={currentBranch.longitude} />
                                        </div>
                                        <div className="col-6">
                                            <label>Latitude</label>
                                            <input name="latitude" className="form-control" value={currentBranch.latitude} onChange={e => setCurrentBranch({ ...currentBranch, latitude: Number(e.target.value) })} />
                                            <InvalidSpan value={currentBranch.latitude} />
                                        </div>
                                        <div className="col-6">
                                            <label>Disable ordering</label>
                                            <input type="checkbox" name="disableOrdering" className="form-control" checked={currentBranch.disableOrdering} onChange={e => setCurrentBranch({ ...currentBranch, disableOrdering: e.target.checked })} />
                                        </div>
                                        <div className="col-6">
                                            <label>index</label>
                                            <input name="index" className="form-control" value={currentBranch.index} onChange={e => setCurrentBranch({ ...currentBranch, index: Number(e.target.value) })} />
                                            <InvalidSpan value={currentBranch.index} />
                                        </div>
                                        <div className="col-12">
                                            <form id="daysForm" className="row m-3 mt-5">
                                                <h4 className="col-12">Working hours</h4>
                                                <div className="col-6">
                                                    <label>Day of week</label>
                                                    <select id="daysOfWeek" name="dayOfWeek" className="form-control">
                                                        <option value="1">Sunday</option>
                                                        <option value="2">Monday</option>
                                                        <option value="3">Tuesday</option>
                                                        <option value="4">Wednesday</option>
                                                        <option value="5">Thursday</option>
                                                        <option value="6">Friday</option>
                                                        <option value="7">Saturday</option>
                                                    </select>
                                                </div>
                                                <div className="col-6">
                                                    <label>Day is open</label>
                                                    <input type="checkbox" name="dayIsOpen" className="form-control" />
                                                </div>
                                                <div className="col-6">
                                                    <label>Open at</label>
                                                    <input type="time" name="opensAt" className="form-control" required />
                                                </div>
                                                <div className="col-6">
                                                    <label>Close at</label>
                                                    <input type="time" name="closesAt" className="form-control" required />
                                                </div>
                                                <button className="btn btn-outline-primary m-3" type="button" onClick={e => {
                                                    if (document.getElementById('daysForm').checkValidity()) {
                                                        let values = getFormValues('daysForm');
                                                        values.dayIsOpen = values.dayIsOpen == 'on';
                                                        values.dayOfWeek = Number(values.dayOfWeek);
                                                        setCurrentBranch({ ...currentBranch, workingHours: [...currentBranch.workingHours, values] });
                                                    }

                                                }}>Add</button>
                                                <table className="table table-bordered dataTable">
                                                    <thead>
                                                        <tr>
                                                            <th>Day of week</th>
                                                            <th>Day is open</th>
                                                            <th>Open at</th>
                                                            <th>Close at</th>
                                                            <th>Delete</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {currentBranch.workingHours?.map(wh => <tr key={wh.id}>
                                                            <td>{convertToDayName(wh.dayOfWeek)}</td>
                                                            <td className="text-center"><i className={"fa " + (wh.dayIsOpen ? "fa-check text-success" : "fa-close text-danger")}></i></td>
                                                            <td>{wh.opensAt}</td>
                                                            <td>{wh.closesAt}</td>
                                                            <td className="text-center">
                                                                <i className="fa fa-trash" onClick={e => {
                                                                    setCurrentBranch({ ...currentBranch, workingHours: currentBranch.workingHours.filter(h => h.id != wh.id) });
                                                                }}></i>
                                                            </td>
                                                        </tr>)}
                                                    </tbody>
                                                </table>
                                            </form>
                                        </div>
                                    </form>
                                </div>

                                <div className="modal-footer">
                                    <button className="btn btn-secondary" type="button" data-dismiss="modal">Cancel</button>
                                    <button className="btn btn-primary" onClick={e => {
                                        let rules = {
                                            "code": v => v != '' && v != null,
                                            "name": v => v != '' && v != null,
                                            "nameTranslations": v => v != '' && v != null,
                                            "phoneNumber": v => v != '' && v != null,
                                            "longitude": v => v != '' && v != null && !isNaN(v),
                                            "latitude": v => v != '' && v != null && !isNaN(v),
                                            "index": v => v != '' && v != null && !isNaN(v),
                                        };

                                        if (Object.keys(rules).every(r => rules[r](currentBranch[r]))) {
                                            RestaurantBrancheService.Update(currentBranch)
                                                .then(r => {
                                                    $('#exampleModal').modal('hide');
                                                    if (r?.data?.code == 200) {
                                                        Swal.fire({
                                                            title: 'Profile updated',
                                                            text: 'Profile updated successfully',
                                                            icon: 'success',
                                                            confirmButtonText: 'Ok'
                                                        }).then(a => {

                                                        })
                                                    } else {
                                                        Swal.fire({
                                                            title: 'Failed',
                                                            text: 'Profile updated failed',
                                                            icon: 'error',
                                                            confirmButtonText: 'Ok'
                                                        }).then(a => {

                                                        })
                                                    }
                                                });
                                        } else {
                                            console.log('invalid form')
                                        }


                                    }}>Update</button>
                                </div>

                            </div>
                        </div>
                    </div>


                </div>

            </div>

            <footer className="sticky-footer bg-white">
                <div className="container my-auto">
                    <div className="copyright text-center my-auto">
                        <span>Copyright &copy; FUAIS 2023</span>
                    </div>
                </div>
            </footer>

        </div>

    </div>
}