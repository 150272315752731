import axios from "axios";
import { Service } from "./Service";

class RestaurantService extends Service {
    name = 'Restaurant';

    async GetProfileRestaurant() {
        let obj = {};
        let r = await axios.get(this.baseURL + this.name + '/GetProfileRestaurant', this.config);
        if (r.status == 200) {
            console.log(r);
            obj = r.data.result;
        }
        return obj;
    }

    async ChangeProfileRestaurant(formData) {
        let obj = {};
        let r = await axios.post(this.baseURL + this.name + '/ChangeProfileRestaurant', formData, this.configMultiPart);
        if (r.status == 200) {
            obj = r;
        }
        return r;
    }


    async GetExtraSections() {
        let obj = {};
        let r = await axios.get(this.baseURL + this.name + '/GetExtraSections', this.config);
        if (r.status == 200) {
            obj = r.data.result;
        }
        return obj;
    }


    async AddEditExtraSection(obj) {
        let r = await axios.post(this.baseURL + this.name + '/AddEditExtraSection', obj, this.config);
        return r;
    }

    async DeleteExtraSection(id) {
        let r = await axios.post(this.baseURL + this.name + '/DeleteExtraSection', { id: id }, this.config);
        return r;
    }


    async GetRestaurants() {
        let obj = {};
        let r = await axios.get(this.baseURL + this.name + '/GetRestaurants', this.config);
        if (r.status == 200) {
            obj = r.data.result;
        }
        return obj;
    }


    async AddEditExtraItem(obj) {
        let r = await axios.post(this.baseURL + this.name + '/AddEditExtraItem', obj, this.config);
        if (r.status == 200) {
            obj = r.data.result;
        }
        return obj;
    }



}


export default new RestaurantService();