import { useEffect, useRef, useState } from "react"
import MenuService from "../../Services/MenuService";
import SideBar from "../common/SideBar";
import Options from "../common/Options";
import { Link } from "react-router-dom";
import ItemService from "../../Services/ItemService";
import RestaurantService from "../../Services/RestaurantService";


const $ = window.jQuery;



export default function ExtraSection() {


    const [isValid, setIsValid] = useState(true);

    const [data, setData] = useState([]);
    const [extraSection, setExtraSectionData] = useState({
        id: '',
        name: '',
        nameTranslations: '',
        isRequired: false
    });

    const [extraItem, setExtraItem] = useState({
        "id": "",
        "code": "",
        "name": "",
        "nameTranslations": "",
        "price": 0.0
    });


    const update = () => {
        RestaurantService.GetExtraSections().then(r => {
            if (Array.isArray(r)) {
                setData(r);
            }
        });
    }
    useEffect(update, []);

    console.log(data);


    const updateExtraItem = (extraItem) => {
        RestaurantService.AddEditExtraItem(extraItem).then(
            r => {
                console.log(r);
            }
        );
    }


    const openExtraSectionAdd = () => {
        setExtraSectionData({
            id: '',
            name: '',
            nameTranslations: '',
            isRequired: false
        });
        $('#AddEditMenuModal').modal('show');
    };

    const openExtraSectionEdit = m => {
        setExtraSectionData({ id: m.id, name: m.name, nameTranslations: m.nameTranslations, isRequired: m.isRequired });
        $('#AddEditMenuModal').modal('show');
    }


    const deleteExtraSection = m => {
        if (window.confirm('Are you sure?')) {
            RestaurantService.DeleteExtraSection(m.id).then(r => {
                update();
            });
        }
    }


    const openExtraItemAdd = (m) => {
        setExtraItem({
            "id": "",
            "code": "",
            "name": "",
            "nameTranslations": "",
            "price": 0.0,
            "extraSectionId": m.id
        });

        $('#AddEditExtraItem').modal('show');
    };
    const openExtraItemEdit = m => {
        setExtraItem({ id: m.id, name: m.name, nameTranslations: m.nameTranslations, code: m.code, price: m.price, extraSectionId: m.extraSectionId });
        $('#AddEditExtraItem').modal('show');
    }
    return <>
        <div id="wrapper">
            <SideBar />


            <div id="content-wrapper" className="d-flex flex-column">

                <div id="content">


                    <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">


                        <button type="button" className="btn btn-success" style={{ width: '140px' }} onClick={openExtraSectionAdd}>Add new extra section</button>
                        <form className="form-inline">
                            <button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3">
                                <i className="fa fa-bars"></i>
                            </button>
                        </form>


                        <Options />

                    </nav>

                    <div className="container-fluid">

                        <h1 className="h3 mb-2 text-gray-800">Extra Section</h1>


                        {
                            data?.map(m => {
                                return <div id={"accordion" + m.id} className="mt-5">
                                    <div className="card">
                                        <div className="card-header collapsed" id={"headingOne" + m.id} data-toggle="collapse" data-target={"#collapseOne" + m.id}
                                            aria-expanded="false" aria-controls={"collapseOne" + m.id}>
                                            <h5 className="mb-0">
                                                <div className="btn btn-link d-block">
                                                    <div className="row">
                                                        {m.name}

                                                        <div className="col text-right">
                                                            <button type="button" className="btn btn-info" onClick={() => openExtraSectionEdit(m)}>Edit</button>
                                                            <button type="button" className="btn btn-danger" onClick={e => deleteExtraSection(m)}>Delete</button>
                                                            <button onClick={e => openExtraItemAdd(m)} className="btn btn-success" role="button">Add new Item</button>

                                                        </div>
                                                    </div>
                                                </div>

                                            </h5>
                                        </div>

                                        <div id={"collapseOne" + m.id} className="collapse" aria-labelledby={"headingOne" + m.id}
                                            data-parent={"#accordion" + m.id}>


                                            <div className="col">
                                                <div className="row" style={{ margin: '10px' }}>


                                                </div>

                                                <div className="container" style={{ maxWidth: '100%' }}>
                                                    <div className="row">
                                                        {
                                                            m.extraItems.map(item => <div className="col-12" style={{ margin: '3px' }}>
                                                                <div className="card">
                                                                    <div className="card-body">
                                                                        <h5 className="card-title">
                                                                            {item.name}
                                                                            <div className="float-right">
                                                                                <button className="btn btn-info"
                                                                                    style={{ margin: '10px' }} onClick={e => openExtraItemEdit(item)}>Edit</button>
                                                                                <button type="button" className="btn btn-danger "
                                                                                    style={{ margin: '10px' }} onClick={e => {
                                                                                        if (window.confirm('Are you sure?')) {
                                                                                            ItemService.Delete(item.id).then(r => {
                                                                                                update();
                                                                                            });
                                                                                        }
                                                                                    }}>Delete</button>
                                                                            </div>
                                                                        </h5>
                                                                        <p className="card-text">
                                                                            Price : {item.price} JD
                                                                        </p>

                                                                    </div>
                                                                </div>
                                                            </div>)
                                                        }

                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            })
                        }



                    </div>
                </div>
                <footer className="sticky-footer bg-white">
                    <div className="container my-auto">
                        <div className="copyright text-center my-auto">
                            <span>Copyright &copy; FUAIS 2023</span>
                        </div>
                    </div>
                </footer>
            </div>
        </div>



        <div className="modal fade" id="AddEditMenuModal" tabIndex="-1" role="dialog" aria-labelledby="AddEditMenuModal"
            aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="AddEditMenuModalLabel">{extraSection.id == '' ? 'Add' : 'Edit'} Extra section</h5>
                        <button className="close" type="button" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-12">
                                <label>Name</label>
                                <input
                                    className={`form-control ${isValid ? '' : 'is-invalid'}`}
                                    value={extraSection.name}
                                    onChange={e => {
                                        const value = e.target.value;
                                        setExtraSectionData({ ...extraSection, name: value });
                                        if (value.trim().length > 0) {
                                            setIsValid(true);
                                        } else {
                                            setIsValid(false);
                                        }
                                    }}
                                />
                                {!isValid && <div className="invalid-feedback">Name is required.</div>}
                            </div>
                            <div className="col-12">
                                <label>Name translation</label>
                                <input className="form-control" value={extraSection.nameTranslations} onChange={e => setExtraSectionData({ ...extraSection, nameTranslations: e.target.value })} />
                            </div>
                            <div className="col-12">
                                <label>Item Required</label>
                                <input type="checkbox" className="form-control" checked={extraSection.isRequired} onChange={e => setExtraSectionData({ ...extraSection, isRequired: e.target.checked })} />
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button className="btn btn-secondary" type="button" data-dismiss="modal">Cancel</button>
                        <button className="btn btn-primary" onClick={e => {
                            let requestData;
                            if (extraSection.id == '') {
                                requestData = { id: null, name: extraSection.name, nameTranslations: extraSection.nameTranslations, isRequired: extraSection.isRequired };
                            } else {
                                requestData = extraSection;
                            }
                            RestaurantService.AddEditExtraSection(requestData).then(r => {
                                update();
                                $('#AddEditMenuModal').modal('hide');
                            });
                        }}>{extraSection.id == '' ? 'Add' : 'Edit'}</button>
                    </div>
                </div>
            </div>
        </div>




        <div className="modal fade" id="AddEditExtraItem" tabIndex="-1" role="dialog" aria-labelledby="AddEditExtraItem"
            aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="AddEditExtraItemLabel">{extraSection.id == '' ? 'Add' : 'Edit'} Extra Item</h5>
                        <button className="close" type="button" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-12">
                                <label>Name</label>
                                <input
                                    className={`form-control ${isValid ? '' : 'is-invalid'}`}
                                    value={extraItem.name}
                                    onChange={e => {
                                        const value = e.target.value;
                                        setExtraItem({ ...extraItem, name: value });
                                        if (value.trim().length > 0) {
                                            setIsValid(true);
                                        } else {
                                            setIsValid(false);
                                        }
                                    }}
                                />
                                {!isValid && <div className="invalid-feedback">Name is required.</div>}
                            </div>
                            <div className="col-12">
                                <label>Name translation</label>
                                <input className="form-control" value={extraItem.nameTranslations} onChange={e => setExtraItem({ ...extraItem, nameTranslations: e.target.value })} />
                            </div>
                            <div className="col-12">
                                <label>Code</label>
                                <input className="form-control" value={extraItem.code} onChange={e => setExtraItem({ ...extraItem, code: e.target.value })} />
                            </div>
                            <div className="col-12">
                                <label>Price</label>
                                <input className="form-control" value={extraItem.price} onChange={e => setExtraItem({ ...extraItem, price: Number(e.target.value) })} />
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button className="btn btn-secondary" type="button" data-dismiss="modal">Cancel</button>
                        <button className="btn btn-primary" onClick={e => {
                            let requestData;
                            if (extraItem.id == '') {
                                requestData = { id: null, name: extraItem.name, nameTranslations: extraItem.nameTranslations, code: extraItem.code, price: extraItem.price, extraSectionId: extraItem.extraSectionId };
                            } else {
                                requestData = extraItem;
                            }
                            RestaurantService.AddEditExtraItem(requestData).then(r => {
                                update();
                                $('#AddEditExtraItem').modal('hide');
                            });
                        }}>{extraItem.id == '' ? 'Add' : 'Edit'}</button>
                    </div>
                </div>
            </div>
        </div>



        <div className="modal fade" id="AddMenuModal" tabIndex="-1" role="dialog" aria-labelledby="AddMenuModalLabel"
            aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="AddMenuModalLabel">Ready to Leave?</h5>
                        <button className="close" type="button" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body">Select "Logout" below if you are ready to end your current session.</div>
                    <div className="modal-footer">
                        <button className="btn btn-secondary" type="button" data-dismiss="modal">Cancel</button>
                        <a className="btn btn-primary" href="login.html">Logout</a>
                    </div>
                </div>
            </div>
        </div>

        <a className="scroll-to-top rounded" href="#page-top">
            <i className="fas fa-angle-up"></i>
        </a>

        <div className="modal fade" id="logoutModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel"
            aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Ready to Leave?</h5>
                        <button className="close" type="button" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body">Select "Logout" below if you are ready to end your current session.</div>
                    <div className="modal-footer">
                        <button className="btn btn-secondary" type="button" data-dismiss="modal">Cancel</button>
                        <a className="btn btn-primary" href="login.html">Logout</a>
                    </div>
                </div>
            </div>
        </div></>

}