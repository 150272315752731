

import { Service } from "./Service";
import axios from "axios";

import { URL } from '../Util/URL'

class OrderService extends Service {
    name = 'Order';
    async GetOrdersReport(filter) {
        let result = [];
        let r = await axios.get(this.baseURL + this.name + '/GetOrdersReport' + URL.getQueryStringFromObject(filter) , this.config);
        console.log(r);
        if (r.status == 200) {
            result = r.data.result;
        }
        return result;
    }

    async GetDashboardOrdersReport(fromdate=null, todate=null) {
        let result = [];
        let r = await axios.get(this.baseURL + this.name + `/GetDashboardOrdersReport?skip=0&take=100${fromdate? '&FromDate=' + fromdate: ''}${todate? '&ToDate=' + todate: ''}`, this.config);
        console.log(r);
        if (r.status == 200) {
            result = r.data.result;
        }
        return result;
    }


    async AcceptOrder(Id) {
        let r = await axios.post(this.baseURL + this.name + '/AcceptOrder', { Id: Id }, this.config);
        console.log(Id, r);
        return r;
    } 

    async PreparedOrder(Id) {
        let r = await axios.post(this.baseURL + this.name + '/PreparedOrder', { Id: Id }, this.config);
        console.log(Id, r);
        return r;
    } 

    async FinishOrder(Id) {
        let r = await axios.post(this.baseURL + this.name + '/FinishOrder', { Id: Id }, this.config);
        console.log(Id, r);
        return r;
    } 


    async RejectOrder(Id) {
        let r = await axios.post(this.baseURL + this.name + '/RejectOrder', { Id: Id }, this.config);
        console.log(Id, r);
        return r;
    } 



    
}


export default new OrderService();