import { useEffect, useRef, useState } from "react";
import RestaurantService from "../../Services/RestaurantService";
import Options from "../common/Options";
import SideBar from "../common/SideBar";
import MenuService from "../../Services/MenuService";
import ItemService from "../../Services/ItemService";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";


export default function AddEditItem() {


    const { menuId, itemId } = useParams();

    const navigate = useNavigate();

    const [isValid, setIsValid] = useState(true);

    const getExtraSections = () => {
        RestaurantService.GetExtraSections().then(r => {
            setExtraSections(r);
        });
    }

    useEffect(() => {
        if (itemId != null) {
            ItemService.GetById(itemId).then(r => {
                console.log(r);
                setData(r[0]);
            });
        }
        getExtraSections();
    }, []);
    const [extraSections, setExtraSections] = useState([]);
    const [data, setData] = useState({
        "id": "",
        "code": "",
        "menuId": "",
        "name": "",
        "nameTranslations": "",
        "description": "",
        "descriptionTranslations": "",
        "image": "",
        "price": 0,
        "priceBeforeDiscount": 0,
        "kcl": 0,
        "calories": 0,
        "carb": 0,
        "protein": 0,
        "fat": 0,
        "fiber": 0,
        "sugar": 0,
        "index": 0,
        "available": false,
        "orderable": false,
        "newItem": false,
        "vegetarian": false,
        "spicy": false,
        "fish": false,
        "nuts": false,
        "peanuts": false,
        "gluten": false,
        "milk": false,
        "shellfish": false,
        "eggs": false,
        "sesame": false,
        "crustacean": false,
        "celery": false,
        "mustard": false,
        "lupins": false,
        "sulphite": false,
        "extraSectionIds": []
    });

    const imageRef = useRef();
    //const coverImageRef = useRef();



    // useEffect(() => {
    //     ItemService.then(
    //         r => {
    //             console.log(r[0]);
    //             setData(r[0]);
    //         }
    //     );
    // }, []);
    return <>
        <div id="wrapper">
            <SideBar />
            <div id="content-wrapper" className="d-flex flex-column">
                <div id="content">
                    <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
                        <button type="button" className="btn btn-success" style={{ width: '140px' }} onClick={e => {
                            console.log(data);
                            let formData = new FormData();
                            //formData.append("id", data.id);
                            if (itemId != null) {
                                formData.append("id", itemId);
                            }
                            formData.append("code", data.code);
                            formData.append("menuId", menuId);
                            formData.append("name", data.name);
                            formData.append("nameTranslations", data.nameTranslations);
                            formData.append("description", data.description);
                            formData.append("descriptionTranslations", data.descriptionTranslations);
                            formData.append("image", imageRef.current.files[0]);
                            formData.append("price", data.price);
                            formData.append("priceBeforeDiscount", data.priceBeforeDiscount);
                            formData.append("kcl", data.kcl);
                            formData.append("calories", data.calories);
                            formData.append("carb", data.carb);
                            formData.append("protein", data.protein);
                            formData.append("fat", data.fat);
                            formData.append("fiber", data.fiber);
                            formData.append("sugar", data.sugar);
                            formData.append("index", data.index);
                            formData.append("available", data.available);
                            formData.append("orderable", data.orderable);
                            formData.append("newItem", data.newItem);
                            formData.append("vegetarian", data.vegetarian);
                            formData.append("spicy", data.spicy);
                            formData.append("fish", data.fish);
                            formData.append("nuts", data.nuts);
                            formData.append("peanuts", data.peanuts);
                            formData.append("gluten", data.gluten);
                            formData.append("milk", data.milk);
                            formData.append("shellfish", data.shellfish);
                            formData.append("eggs", data.eggs);
                            formData.append("sesame", data.sesame);
                            formData.append("crustacean", data.crustacean);
                            formData.append("celery", data.celery);
                            formData.append("mustard", data.mustard);
                            formData.append("lupins", data.lupins);
                            formData.append("sulphite", data.sulphite);
                            data.extraSectionIds?.forEach((es, i, a) => {
                                formData.append(`extraSectionIds[${i}]`, es);
                            });

                            ItemService.AddEditItem(formData).then(r => {
                                console.log(r);
                                if (r) {
                                    Swal.fire({
                                        title: 'Item added',
                                        text: 'Item added successfully',
                                        icon: 'success',
                                        confirmButtonText: 'Ok'
                                    }).then(a => {
                                        navigate('/Menu');
                                    })
                                } else {
                                    Swal.fire({
                                        title: 'Error!',
                                        text: 'Could not add the item',
                                        icon: 'error',
                                        confirmButtonText: 'Ok'
                                    })
                                }
                            });

                            if (data.name.trim() === '') {
                                setIsValid(false);
                                return;
                            }

                        }}>Save</button>
                        <form className="form-inline">
                            <button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3">
                                <i className="fa fa-bars"></i>
                            </button>
                        </form>
                        <Options />
                    </nav>
                    <div className="container-fluid">
                        <h1 className="h3 mb-2 text-gray-800">Item</h1>
                        <div className="text-center">
                            <img src={data.image}
                                style={{ margin: '15px' }} className="rounded float-left" alt="..." width="120px" />

                        </div>
                        <div className="input-group mb-3">
                            <div className="input-group-prepend">
                                <span className="input-group-text">Upload Item image </span>
                            </div>
                            <div className="custom-file">
                                <input type="file" className="custom-file-input" id="inputGroupFile01" ref={imageRef} />
                                <label className="custom-file-label" for="inputGroupFile01">Choose file</label>
                            </div>
                        </div>
                        <div className="input-group mb-3">
                            <div className="input-group-prepend">
                                <span className="input-group-text">Name</span>
                            </div>
                            <input type="text" className="form-control" placeholder="Name" aria-label="Username"
                                aria-describedby="basic-addon1" value={data.name} onChange={(e) => setData({ ...data, name: e.target.value })} />
                        </div>
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text">Description in English</span>
                            </div>
                            <textarea className="form-control" aria-label="Description" value={data.description} onChange={(e) => setData({ ...data, description: e.target.value })}></textarea>
                        </div>
                        <br />
                        <div className="input-group  mb-3">
                            <div className="input-group-prepend">
                                <span className="input-group-text">Description in Arabic</span>
                            </div>
                            <textarea className="form-control" aria-label="Description" value={data.descriptionTranslations} onChange={(e) => setData({ ...data, descriptionTranslations: e.target.value })}></textarea>
                        </div>
                        <div className="input-group mb-3">
                            <div className="input-group-prepend">
                                <span className="input-group-text">Code</span>
                            </div>
                            <input type="text" className="form-control" placeholder="Code" aria-label="Username"
                                aria-describedby="basic-addon1" value={data.code} onChange={(e) => setData({ ...data, code: e.target.value })} />
                        </div>


                        <hr />

                        <div className="custom-switch d-inline-block mx-3">
                            <input type="checkbox" className="custom-control-input" id="customSwitches" checked={data.available} onChange={(e) => setData({ ...data, available: e.target.checked })} />
                            <label className="custom-control-label" for="customSwitches">Available</label>
                        </div>
                        <div className="custom-switch d-inline-block mx-3">
                            <input type="checkbox" className="custom-control-input" id="customSwitches2" checked={data.orderable} onChange={(e) => setData({ ...data, orderable: e.target.checked })} />
                            <label className="custom-control-label" for="customSwitches2">Orderable</label>
                        </div>
                        <div className="custom-switch d-inline-block mx-3">
                            <input type="checkbox" className="custom-control-input" id="customSwitches3" checked={data.newItem} onChange={(e) => setData({ ...data, newItem: e.target.checked })} />
                            <label className="custom-control-label" for="customSwitches3">NewItem</label>
                        </div>
                        <div className="custom-switch d-inline-block mx-3">
                            <input type="checkbox" className="custom-control-input" id="customSwitches4" checked={data.vegetarian} onChange={(e) => setData({ ...data, vegetarian: e.target.checked })} />
                            <label className="custom-control-label" for="customSwitches4">Vegetarian</label>
                        </div>
                        <div className="custom-switch d-inline-block mx-3">
                            <input type="checkbox" className="custom-control-input" id="customSwitches5" checked={data.spicy} onChange={(e) => setData({ ...data, spicy: e.target.checked })} />
                            <label className="custom-control-label" for="customSwitches5">Spicy</label>
                        </div>
                        <div className="custom-switch d-inline-block mx-3">
                            <input type="checkbox" className="custom-control-input" id="customSwitches6" checked={data.fish} onChange={(e) => setData({ ...data, fish: e.target.checked })} />
                            <label className="custom-control-label" for="customSwitches6">Fish</label>
                        </div>
                        <div className="custom-switch d-inline-block mx-3">
                            <input type="checkbox" className="custom-control-input" id="customSwitches7" checked={data.nuts} onChange={(e) => setData({ ...data, nuts: e.target.checked })} />
                            <label className="custom-control-label" for="customSwitches7">Nuts</label>
                        </div>
                        <div className="custom-switch d-inline-block mx-3">
                            <input type="checkbox" className="custom-control-input" id="customSwitches8" checked={data.peanuts} onChange={(e) => setData({ ...data, peanuts: e.target.checked })} />
                            <label className="custom-control-label" for="customSwitches8">Peanuts</label>
                        </div>
                        <div className="custom-switch d-inline-block mx-3">
                            <input type="checkbox" className="custom-control-input" id="customSwitches9" checked={data.gluten} onChange={(e) => setData({ ...data, gluten: e.target.checked })} />
                            <label className="custom-control-label" for="customSwitches9">Gluten</label>
                        </div>
                        <div className="custom-switch d-inline-block mx-3">
                            <input type="checkbox" className="custom-control-input" id="customSwitches10" checked={data.milk} onChange={(e) => setData({ ...data, milk: e.target.checked })} />
                            <label className="custom-control-label" for="customSwitches10">Milk</label>
                        </div>
                        <div className="custom-switch d-inline-block mx-3">
                            <input type="checkbox" className="custom-control-input" id="customSwitches11" checked={data.shellfish} onChange={(e) => setData({ ...data, shellfish: e.target.checked })} />
                            <label className="custom-control-label" for="customSwitches11">Shellfish</label>
                        </div>
                        <div className="custom-switch d-inline-block mx-3">
                            <input type="checkbox" className="custom-control-input" id="customSwitches12" checked={data.eggs} onChange={(e) => setData({ ...data, eggs: e.target.checked })} />
                            <label className="custom-control-label" for="customSwitches12">Eggs</label>
                        </div>
                        <div className="custom-switch d-inline-block mx-3">
                            <input type="checkbox" className="custom-control-input" id="customSwitches13" checked={data.sesame} onChange={(e) => setData({ ...data, sesame: e.target.checked })} />
                            <label className="custom-control-label" for="customSwitches13">Sesame</label>
                        </div>
                        <div className="custom-switch d-inline-block mx-3">
                            <input type="checkbox" className="custom-control-input" id="customSwitches14" checked={data.crustacean} onChange={(e) => setData({ ...data, crustacean: e.target.checked })} />
                            <label className="custom-control-label" for="customSwitches14">Crustacean</label>
                        </div>
                        <div className="custom-switch d-inline-block mx-3">
                            <input type="checkbox" className="custom-control-input" id="customSwitches15" checked={data.celery} onChange={(e) => setData({ ...data, celery: e.target.checked })} />
                            <label className="custom-control-label" for="customSwitches15">Celery</label>
                        </div>
                        <div className="custom-switch d-inline-block mx-3">
                            <input type="checkbox" className="custom-control-input" id="customSwitches16" checked={data.mustard} onChange={(e) => setData({ ...data, mustard: e.target.checked })} />
                            <label className="custom-control-label" for="customSwitches16">Mustard</label>
                        </div>
                        <div className="custom-switch d-inline-block mx-3">
                            <input type="checkbox" className="custom-control-input" id="customSwitches17" checked={data.lupins} onChange={(e) => setData({ ...data, lupins: e.target.checked })} />
                            <label className="custom-control-label" for="customSwitches17">Lupins</label>
                        </div>
                        <div className="custom-switch d-inline-block mx-3">
                            <input type="checkbox" className="custom-control-input" id="customSwitches18" checked={data.sulphite} onChange={(e) => setData({ ...data, sulphite: e.target.checked })} />
                            <label className="custom-control-label" for="customSwitches18">Sulphite</label>
                        </div>

                        <hr />
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">Price</span>
                                    </div>
                                    <input type="text" className="form-control" placeholder="Price" aria-label="Username"
                                        aria-describedby="basic-addon1" value={data.price} onChange={(e) => setData({ ...data, price: e.target.value })} />
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">Price before discount</span>
                                    </div>
                                    <input type="text" className="form-control" placeholder="Price before discount" aria-label="Username"
                                        aria-describedby="basic-addon1" value={data.priceBeforeDiscount} onChange={(e) => setData({ ...data, priceBeforeDiscount: e.target.value })} />
                                </div>
                            </div>
                            <div className="w-100"></div>
                            <div className="col-sm-6">
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">Kcl</span>
                                    </div>
                                    <input type="text" className="form-control" placeholder="Kcl" aria-label="Username"
                                        aria-describedby="basic-addon1" value={data.kcl} onChange={(e) => setData({ ...data, kcl: e.target.value })} />
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">Calories</span>
                                    </div>
                                    <input type="text" className="form-control" placeholder="Calories" aria-label="Username"
                                        aria-describedby="basic-addon1" value={data.calories} onChange={(e) => setData({ ...data, calories: e.target.value })} />
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">Carb</span>
                                    </div>
                                    <input type="text" className="form-control" placeholder="Carb" aria-label="Username"
                                        aria-describedby="basic-addon1" value={data.carb} onChange={(e) => setData({ ...data, carb: e.target.value })} />
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">Protein</span>
                                    </div>
                                    <input type="text" className="form-control" placeholder="Protein" aria-label="Username"
                                        aria-describedby="basic-addon1" value={data.protein} onChange={(e) => setData({ ...data, protein: e.target.value })} />
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">Fat</span>
                                    </div>
                                    <input type="text" className="form-control" placeholder="Fat" aria-label="Username"
                                        aria-describedby="basic-addon1" value={data.fat} onChange={(e) => setData({ ...data, fat: e.target.value })} />
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">Fiber</span>
                                    </div>
                                    <input type="text" className="form-control" placeholder="Fiber" aria-label="Username"
                                        aria-describedby="basic-addon1" value={data.fiber} onChange={(e) => setData({ ...data, fiber: e.target.value })} />
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">Sugar</span>
                                    </div>
                                    <input type="text" className="form-control" placeholder="Sugar" aria-label="Username"
                                        aria-describedby="basic-addon1" value={data.sugar} onChange={(e) => setData({ ...data, sugar: e.target.value })} />
                                </div>
                            </div>
                            {/* <div className="col-sm-6">
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">Index</span>
                                    </div>
                                    <input type="text" className="form-control" placeholder="Index" aria-label="Username"
                                        aria-describedby="basic-addon1" value={data.index} onChange={(e) => setData({ ...data, index: e.target.value })} />
                                </div>
                            </div> */}
                            <hr />
                        </div>
                        <hr />
                        {extraSections.map(es => <div className="custom-switch d-inline-block mx-3">
                            <input type="checkbox" className="custom-control-input" id={"customSwitches" + es.id} onChange={(e) => {
                                if(e.target.checked) {
                                    setData( { ...data, extraSectionIds:  [...data.extraSectionIds??[], es.id]} );
                                } else {
                                    setData({ ...data, extraSectionIds: data.extraSectionIds.filter(esid => esid != es.id) });
                                }
                                console.log(data.extraSectionIds);
                            }} />
                            <label className="custom-control-label" for={"customSwitches" + es.id}>{es.name}</label>
                        </div>)}
                    </div>
                </div>
                <footer className="sticky-footer bg-white">
                    <div className="container my-auto">
                        <div className="copyright text-center my-auto">
                            <span>Copyright &copy; FUAIS 2023</span>
                        </div>
                    </div>
                </footer>

            </div>

        </div>

        <a className="scroll-to-top rounded" href="#page-top">
            <i className="fas fa-angle-up"></i>
        </a>

        <div className="modal fade" id="logoutModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel"
            aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Ready to Leave?</h5>
                        <button className="close" type="button" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body">Select "Logout" below if you are ready to end your current session.</div>
                    <div className="modal-footer">
                        <button className="btn btn-secondary" type="button" data-dismiss="modal">Cancel</button>
                        <a className="btn btn-primary" href="login.html">Logout</a>
                    </div>
                </div>
            </div>
        </div>
    </>;

}