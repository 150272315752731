import axios from "axios";
import { Service } from "./Service";

class UserService extends Service {
    name = 'User';

    
    async GetCurrentUser() {
        let obj = {};
        let r = await axios.get(this.baseURL + this.name + '/GetCurrentUser', this.config);
        if (r.status == 200) {
            obj = r.data.result;
        }
        return obj;
    }


}


export default new UserService();