



import { Service } from "./Service";
import axios from "axios";

class DeliveryPriceService extends Service {
    name = 'DeliveryPrice';


    async GetMenu() {
        let result = [];
        let r = await axios.get(this.baseURL + this.name + '/GetMenu', this.config);
        if (r.status == 200) {
            result = r.data.result;
        }
        return result;
    }

    async Import(obj) {
        let r = await axios.post(this.baseURL + this.name + '/Import', obj, this.config);
        if (r.status == 200) {

        }
    }


}


export default new DeliveryPriceService();



