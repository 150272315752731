import { useEffect, useReducer, useRef } from "react";
import { useNavigate } from "react-router-dom";

export default function useController(controller) {
    controller.formRef = useRef();

    let [state, dispatcher] = useReducer(controller.reducer, controller.state);
    controller.setDispatcher(dispatcher);
    useEffect(controller.main, []);
    return [state, dispatcher];

}